import { Box, Typography, Stack, FormHelperText, Divider, Collapse } from '@material-ui/core';
import ColourPicker from '../../../components/pickers/ColourPicker';
import {
  HEADER_APPEARANCE,
  HEADER_APPEARANCE_FILL,
  HEADER_APPEARANCE_FILL_WITH_SCROLL,
  NAVIGATION_ITEM_APPEARANCE
} from '../../../utils/constantsEditor';
import MultiOptionPicker from '../../../components/pickers/MultiOptionPicker';
import { HeaderStylePicker } from '../../../components/pickers/HeaderStylePicker';

export default function HeaderStyleEditorCommon({ form, enableNavigation }) {
  const { values, errors, setFieldValue } = form;

  // Component collapse
  const showBackgroundColours =
    values.appearanceFill !== HEADER_APPEARANCE_FILL[0].value || values.appearance === HEADER_APPEARANCE[0];
  const showScrollColoursHint =
    values.appearanceFill === HEADER_APPEARANCE_FILL_WITH_SCROLL[1].value ||
    values.appearanceFill === HEADER_APPEARANCE_FILL_WITH_SCROLL[2].value;

  return (
    <>
      <Divider />

      <div>
        <Typography variant="body1">
          <strong>Header Appearance</strong>
        </Typography>
        <Collapse in={values.appearance === HEADER_APPEARANCE[1] || values.appearance === HEADER_APPEARANCE[3]}>
          <FormHelperText>
            Sticky position will remain at the top of the page at all times.&nbsp;
            <strong>This is not visible in preview mode, but this will be applied when deployed.</strong>
          </FormHelperText>
        </Collapse>
      </div>

      <Box>
        <HeaderStylePicker value={values.appearance} onChange={(newValue) => setFieldValue('appearance', newValue)} />
        <Collapse in={values.appearance === HEADER_APPEARANCE[3]}>
          <FormHelperText>
            Hybrid is a mixture of an <strong>overlap</strong> header but will <strong>stick</strong> to the top of the
            page
          </FormHelperText>
        </Collapse>
      </Box>

      <Collapse in={values.appearance !== 'STANDARD'}>
        <Stack spacing={1}>
          <Typography variant="overline" color="text.secondary">
            <strong>Background Style</strong>
          </Typography>
          <div>
            <MultiOptionPicker
              data={values.appearance?.includes('STICKY') ? HEADER_APPEARANCE_FILL_WITH_SCROLL : HEADER_APPEARANCE_FILL}
              value={values.appearanceFill}
              isScrollable={values.appearance?.includes('STICKY')}
              onChange={(_, newValue) => setFieldValue('appearanceFill', newValue)}
            />
          </div>
          <Collapse in={showScrollColoursHint} sx={{ ...(!showScrollColoursHint && { display: 'none' }) }}>
            <FormHelperText>Background colour will be used only when scrolling on the page has begun.</FormHelperText>
          </Collapse>
        </Stack>
      </Collapse>

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Colours</strong>
        </Typography>
        <Typography variant="overline" color="text.secondary">
          <strong>Header</strong>
        </Typography>
        <Collapse in={showBackgroundColours} sx={{ ...(!showBackgroundColours && { display: 'none' }) }}>
          <div>
            <ColourPicker
              title="Background"
              value={values.backgroundColour}
              handleChangeColour={(value) => setFieldValue('backgroundColour', value)}
              handleClearColour={() => setFieldValue('backgroundColour', null)}
            />
            {errors.backgroundColour && <FormHelperText error>{errors.backgroundColour}</FormHelperText>}
            <FormHelperText>This will change the default colour of the header background</FormHelperText>
          </div>
        </Collapse>

        <div>
          <ColourPicker
            title="Primary"
            value={values.primaryColour}
            handleChangeColour={(value) => setFieldValue('primaryColour', value)}
            handleClearColour={() => setFieldValue('primaryColour', null)}
          />
          {errors.primaryColour && <FormHelperText error>{errors.primaryColour}</FormHelperText>}
          <FormHelperText>This will change the primary colour of the header</FormHelperText>
        </div>
        <div>
          <ColourPicker
            title="Secondary"
            value={values.secondaryColour}
            handleChangeColour={(value) => setFieldValue('secondaryColour', value)}
            handleClearColour={() => setFieldValue('secondaryColour', null)}
          />
          {errors.secondaryColour && <FormHelperText error>{errors.secondaryColour}</FormHelperText>}
          <FormHelperText>This will change the contrast colour of the header</FormHelperText>
        </div>
        <div>
          <ColourPicker
            title="Secondary Contrast"
            value={values.secondaryContrastColour}
            handleChangeColour={(value) => setFieldValue('secondaryContrastColour', value)}
            handleClearColour={() => setFieldValue('secondaryContrastColour', null)}
          />
          {errors.secondaryContrastColour && <FormHelperText error>{errors.secondaryContrastColour}</FormHelperText>}
          <FormHelperText>This will change the contrast colour of the header</FormHelperText>
        </div>
        <div>
          <ColourPicker
            title="Text"
            value={values.textColour}
            handleChangeColour={(value) => setFieldValue('textColour', value)}
            handleClearColour={() => setFieldValue('textColour', null)}
          />
          {errors.textColour && <FormHelperText error>{errors.textColour}</FormHelperText>}
          <FormHelperText>This will change the default colour of the header text</FormHelperText>
        </div>

        <Typography variant="overline" color="text.secondary">
          <strong>Mobile Sidebar</strong>
        </Typography>

        <div>
          <ColourPicker
            title="Background"
            value={values.mobileBackgroundColour}
            handleChangeColour={(value) => setFieldValue('mobileBackgroundColour', value)}
            handleClearColour={() => setFieldValue('mobileBackgroundColour', null)}
          />
          {errors.mobileBackgroundColour && <FormHelperText error>{errors.mobileBackgroundColour}</FormHelperText>}
          <FormHelperText>This will change the default colour of the header background</FormHelperText>
        </div>

        <div>
          <ColourPicker
            title="Text"
            value={values.mobileTextColour}
            handleChangeColour={(value) => setFieldValue('mobileTextColour', value)}
            handleClearColour={() => setFieldValue('mobileTextColour', null)}
          />
          {errors.mobileTextColour && <FormHelperText error>{errors.mobileTextColour}</FormHelperText>}
          <FormHelperText>This will change the default colour of the header text</FormHelperText>
        </div>
      </Stack>

      <Collapse in={enableNavigation}>
        <Stack spacing={3}>
          <Divider />

          <Typography variant="body1">
            <strong>Navigation Appearance</strong>
          </Typography>

          <Stack spacing={2}>
            <Typography variant="overline" color="text.secondary">
              <strong>Desktop</strong>
            </Typography>
            <MultiOptionPicker
              data={NAVIGATION_ITEM_APPEARANCE}
              value={values.navigationViewType}
              onChange={(_, newValue) => setFieldValue('navigationViewType', newValue)}
            />
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
}
