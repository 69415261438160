import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  notifications: null
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startNotificationsLoading(state) {
      state.loading = true;
    },

    // HAS ERROR
    hasNotificationsError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startNotificationsLoading());
    try {
      const response = await axios.get('/manage/notifications');
      dispatch(slice.actions.getNotificationsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasNotificationsError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateNotifications(values) {
  const { orderCompleted, orderFulfilled, bookingCompleted, newsAnnouncement, newsProductUpdates } = values;
  return axios.put('/manage/notifications', {
    orderCompleted,
    orderFulfilled,
    bookingCompleted,
    newsAnnouncement,
    newsProductUpdates
  });
}
