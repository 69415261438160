import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Stack, Button } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { HEADER_APPEARANCE, HEADER_APPEARANCE_FILL, NAVIGATION_ITEM_APPEARANCE } from '../../../utils/constantsEditor';
import HeaderStyleEditorCommon from './HeaderStyleEditorCommon';
import { EditorConatiner } from '../../../common/EditorConatiner';

export default function HeaderStyleEditor({ heading, config, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const enableNavigation = config?.enableNavigation;
  const navigationViewType = config?.navigationViewType;
  const backgroundColour = config?.backgroundColour;
  const textColour = config?.textColour;
  const primaryColour = config?.primaryColour;
  const secondaryColour = config?.secondaryColour;
  const secondaryContrastColour = config?.secondaryContrastColour;
  const mobileTextColour = config?.mobileTextColour;
  const mobileBackgroundColour = config?.mobileBackgroundColour;
  const appearance = config?.appearance;
  const appearanceFill = config?.appearanceFill;

  const form = useFormik({
    initialValues: {
      navigationViewType: defaultTo(navigationViewType, NAVIGATION_ITEM_APPEARANCE[0].value),
      backgroundColour: defaultTo(backgroundColour, ''),
      mobileBackgroundColour: defaultTo(mobileBackgroundColour, ''),
      textColour: defaultTo(textColour, ''),
      primaryColour: defaultTo(primaryColour, ''),
      secondaryColour: defaultTo(secondaryColour, ''),
      secondaryContrastColour: defaultTo(secondaryContrastColour, ''),
      mobileTextColour: defaultTo(mobileTextColour, ''),
      appearance: defaultTo(appearance, HEADER_APPEARANCE[0]),
      appearanceFill: defaultTo(appearanceFill, HEADER_APPEARANCE_FILL[0].value)
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <HeaderStyleEditorCommon form={form} enableNavigation={enableNavigation} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
