import { format } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------
export const DEFAULT_TIMEZONE = 'Europe/London';

const toPlural = (value) => (value > 1 ? 's' : '');

export function fMinutesToLong(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (mins === 0 && hours === 0) {
    return `-`;
  }
  if (hours === 0) {
    return `${mins} Minute${toPlural(mins)}`;
  }
  if (mins === 0) {
    return `${hours} Hour${toPlural(hours)}`;
  }
  return `${hours} Hour${toPlural(hours)} ${mins} Minute${toPlural(mins)}`;
}

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateISO(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTimeLong(date) {
  return moment(date).format('MMMM Do YYYY');
}

// Charts
export function fDayOfWeek(day) {
  return moment().day(day).format('dddd');
}

export function fDayOfMonthShort(date) {
  const formattedDate = moment(date, 'YYYYMMDD');
  return formattedDate.format('Do');
}

export function fDayOfMonthLong(date) {
  const formattedDate = moment(date, 'YYYYMMDD');
  return formattedDate.format('Do MMMM');
}

export function fHourMinShort(hour, min) {
  return moment().hour(hour).minute(min).format('HH:mm');
}

export function fHourShort(hour) {
  return moment().hour(hour).format('h a');
}

export function fHourLong(hour) {
  return moment().hour(hour).format('HH:00 a');
}

export function fMonthShort(date) {
  const formattedDate = moment(date, 'YYYYMM');
  return formattedDate.format('MMMM');
}

export function fMonthLong(date) {
  const formattedDate = moment(date, 'YYYYMM');
  return formattedDate.format('MMMM yyyy');
}
